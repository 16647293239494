export const metricTooltips = {
  activationRate: 'Total core assets activated divided by total core assets',
  adFormats: 'Total ad formats where ads were activated',
  adFormatsActivated: 'Total ad formats where core assets were activated',
  adImpressions: 'Total impressions behind ads',
  adMediaSpend: 'Total media spend behind ads',
  averageScore: 'The average score across your ads based on your company’s default score',
  assetsActivated: 'The number of core assets connected to an ad that has media spend',
  assetsNotActivated: 'The number of core assets not connected to an ad that has media spend',
  assets: 'Total core assets uploaded',
  averageSpendPerAsset: 'Average spend behind every ad',
  channels: 'Total channels where ads were activated',
  impressions: 'Total impressions behind all activations of an activated core asset',
  channelsActivated: 'Total channels where core assets were activated',
  markets: 'Total markets where ads were activated',
  marketsActivated: 'Total markets where core assets were activated',
  placements: 'Total placements where ads were activated',
  placementsActivated: 'Total placements where core assets were activated',
  posts: 'Total ads associated to a core asset',
  preflightQualityRate: 'The percentage of Pre-Flight creatives that have met your organization\'s default criteria for an Excellent creative',
  qualityRate: 'The percentage of ads that have met your organization’s default criteria for an Excellent creative',
  repurposedRate: 'Total unique assets connected to a core asset divided by total core assets activated',
  reusageRate: 'Total ads connected to a core asset divided by total core assets activated',
  spend: 'Total media spend behind all activations of an activated core asset',
  totalAssets: 'Total In-Flight assets',
  totalPosts: 'Total In-Flight posts',
};
