import { getSegmentLabel } from 'components/reporting/binItems';
import { NONE_KEY } from 'components/reporting/utilities';
import { EMPTY_VALUE } from 'components/shared/channel';
import {
  toConciseCount,
  toConciseSpend,
  toCount,
  toPercent,
  toSpend,
} from 'utilities/string';

const coreAssetSegments = ['cpeBrand', 'cpeCampaign', 'cpeFilename', 'masterAssetId', 'cpeMeasurementPartner', 'cpeProductionPartner'];

function getBinTag(record, keys) {
  const values = keys.map((key) => record[key]);

  return values.join('::');
}

function getBinSegments(bin, segments) {
  const keys = segments.map((segment) => segment.value);
  const record = bin.records[0];

  if (keys.length === 0) {
    return {
      [NONE_KEY]: { value: 'Total' },
    };
  }

  return keys.reduce((obj, key) => ({
    ...obj,
    [key]: {
      label: getSegmentLabel(record, key),
      value: record[key] ?? EMPTY_VALUE,
    },
  }), {});
}

function getCoreAssetCountsObj(segmentKeys, records) {
  const defaultBin = { uniqueCoreAssets: new Set() };

  return records.reduce((bins, record) => {
    const tag = getBinTag(record, segmentKeys);
    const bin = bins[tag] ?? defaultBin;
    const { masterAssetId } = record;

    return {
      ...bins,
      [tag]: {
        ...bin,
        uniqueCoreAssets:
          masterAssetId ? new Set([...bin.uniqueCoreAssets, masterAssetId]) : bin.uniqueCoreAssets,
      },
    };
  }, {});
}

export function getBins(segments, records) {
  const segmentKeys = segments.map((segment) => segment.value);

  // Create bins of core asset counts to be used in calculations
  const coreAssetKeys = segmentKeys.filter((key) => coreAssetSegments.includes(key));
  const coreAssetCounts = getCoreAssetCountsObj(coreAssetKeys, records);

  const defaultBin = { records: [] };

  // Split the records into unique segmentKey combinations
  return records.reduce((bins, record) => {
    const tag = getBinTag(record, segmentKeys);
    const coreAssetTag = getBinTag(record, coreAssetKeys);

    const bin = bins[tag] ?? defaultBin;

    return {
      ...bins,
      [tag]: {
        ...bin,
        tag,
        coreAssetCount: bin.coreAssetCount ?? coreAssetCounts[coreAssetTag]?.uniqueCoreAssets.size,
        records: [...bin.records, record],
      },
    };
  }, {});
}

function getPreflightRankings(preflight, scoreIds) {
  return scoreIds.reduce((obj, id) => {
    const ranking = preflight ? preflight[`ranking${id}`] : undefined;
    const key = `preflightRanking::${id}`;

    return {
      ...obj,
      [key]: ranking,
    };
  }, {});
}

function getCoreAssetStatus(asset) {
  if (asset.masterAssetId && asset.ids.length > 0) {
    return 'Associated';
  } else if (!asset.masterAssetId && asset.ids.length > 0) {
    return 'Not Associated';
  }
  return null;
}

export function preprocessAssets(assets, preflights, attributeLookups, scores) {
  const scoreIds = scores.map(({ versionId }) => versionId);
  return assets.map((asset) => {
    const preflight = preflights.find((pf) => pf.uuid === asset.masterAssetId);

    return {
      ...asset,
      adFormat: attributeLookups.adFormat[asset.adFormatKey] ?? null,
      brand: attributeLookups.brand[asset.brandId] ?? null,
      channel: attributeLookups.channel[asset.channelKey] ?? null,
      coreAssetStatus: getCoreAssetStatus(asset),
      ...getPreflightRankings(preflight, scoreIds),
    };
  });
}

const parsePost = (post) => {
  const [
    postId,
    spend,
    impressions,
  ] = post.split('::');
  return {
    postId,
    spend: parseFloat(spend),
    impressions: parseInt(impressions),
  };
};

function postDataTotals({ postData }, set) {
  const defaultObj = {
    postIdSet: new Set(),
    spend: 0,
    impressions: 0,
  };
  if (!postData) {
    return defaultObj;
  }
  return postData.reduce((obj, post) => {
    const {
      postId, spend, impressions,
    } = parsePost(post);
    if (set.has(postId) || obj.postIdSet.has(postId)) {
      return obj;
    }
    return {
      postIdSet: new Set([...obj.postIdSet, postId]),
      spend: obj.spend + spend,
      impressions: obj.impressions + impressions,
    };
  }, defaultObj);
}

function updateActivatedSet(set, { masterAssetId, postData }) {
  const hasPosts = postData.length > 0;
  const isActivated = Boolean(masterAssetId) && hasPosts;
  return isActivated ? new Set([...set, masterAssetId]) : set;
}

function parseScorePost(post) {
  const [
    postId,
    rank,
    highestSpend,
    score,
  ] = post.split('::');

  return {
    postId,
    rank,
    highestSpend: parseFloat(highestSpend),
    score: parseFloat(score),
  };
}

function getScoreTotalObj(postScore, set) {
  const defaultObj = {
    highestCount: 0,
    highestSpend: 0,
    scoreSum: 0,
    postIdSet: new Set(),
  };
  if (!postScore) {
    return defaultObj;
  }
  return postScore.reduce((obj, post) => {
    const {
      postId,
      rank,
      highestSpend,
      score,
    } = parseScorePost(post);
    if (set.has(postId) || obj.postIdSet.has(postId)) {
      return obj;
    }
    return {
      highestCount: rank === 'highest' ? obj.highestCount + 1 : obj.highestCount,
      highestSpend: obj.highestSpend + highestSpend,
      scoreSum: obj.scoreSum + score,
      postIdSet: new Set([...obj.postIdSet, postId]),
    };
  }, defaultObj);
}

function scoreDefaults(scoreIds) {
  return scoreIds.reduce((obj, id) => ({
    ...obj,
    [id]: {
      highestCount: 0,
      highestSpend: 0,
      preflightHighestCount: 0,
      scoreSum: 0,
    },
  }), {
    postScoreSet: new Set(),
    preflightMASet: new Set(),
  });
}

function updateScoreTotals(record, scoreIds, set, totals) {
  return scoreIds.reduce((obj, id) => {
    const data = record[`postScore${id}`];
    if (!data) {
      return obj;
    }
    const preflightRank = record[`preflightRanking::${id}`];
    const {
      highestCount,
      highestSpend,
      scoreSum,
      postIdSet,
    } = getScoreTotalObj(data, set);
    return {
      ...obj,
      postScoreSet: new Set([...obj.postScoreSet, ...postIdSet]),
      preflightMASet:
        preflightRank ? new Set([...obj.preflightMASet, record.masterAssetId]) : obj.preflightMASet,
      [id]: {
        highestCount: totals[id].highestCount + highestCount,
        highestSpend: totals[id].highestSpend + highestSpend,
        preflightHighestCount: totals[id].preflightHighestCount + ((preflightRank === 'highest') ? 1 : 0),
        scoreSum: totals[id].scoreSum + scoreSum,
      },
    };
  }, {
    postScoreSet: totals.postScoreSet,
    preflightMASet: totals.preflightMASet,
  });
}

function getBinTotals(records, scores) {
  const scoreIds = scores.map(({ versionId }) => versionId);
  const totals = records.reduce((obj, record) => {
    const {
      postIdSet,
      spend,
      impressions,
    } = postDataTotals(record, obj.postDataSet);
    return {
      ...obj,
      auditAssetSet:
         record.ids ? new Set([...obj.auditAssetSet, ...record.ids]) : obj.auditAssetSet,
      activatedMASet: updateActivatedSet(obj.activatedMASet, record),
      postDataSet: new Set([...obj.postDataSet, ...postIdSet]),
      spend: obj.spend + spend,
      impressions: obj.impressions + impressions,
      adFormatSet:
        record.adFormat ? new Set([...obj.adFormatSet, record.adFormat]) : obj.adFormatSet,
      channelSet: record.channel ? new Set([...obj.channelSet, record.channel]) : obj.channelSet,
      marketSet: record.market ? new Set([...obj.marketSet, record.market]) : obj.marketSet,
      placementSet:
        record.placement ? new Set([...obj.placementSet, record.placement]) : obj.placementSet,
      ...updateScoreTotals(record, scoreIds, obj.postScoreSet, obj),
    };
  }, {
    auditAssetSet: new Set(),
    activatedMASet: new Set(),
    postDataSet: new Set(),
    spend: 0,
    impressions: 0,
    adFormatSet: new Set(),
    channelSet: new Set(),
    marketSet: new Set(),
    placementSet: new Set(),
    ...scoreDefaults(scoreIds),
  });

  const scoreData = scores.reduce((obj, { versionId }) => ({
    ...obj,
    [versionId]: totals[versionId],
  }), {});

  return {
    activatedMasterAssets: totals.activatedMASet.size,
    adFormats: totals.adFormatSet.size,
    auditAssets: totals.auditAssetSet.size,
    channels: totals.channelSet.size,
    impressions: totals.impressions,
    inflightPosts: totals.postDataSet.size,
    markets: totals.marketSet.size,
    placements: totals.placementSet.size,
    preflightMasterAssets: totals.preflightMASet.size,
    scorePosts: totals.postScoreSet.size,
    spend: totals.spend,
    ...scoreData,
  };
}

function scoreMetricDefaults(scoreIds) {
  return scoreIds.reduce((obj, id) => ({
    ...obj,
    [`averageScore::${id}`]: 0,
    [`scoreRate::${id}`]: 0,
    [`preflightScoreRate::${id}`]: 0,
    [`qualitySpendRate::${id}`]: 0,
  }), {});
}

function updateScoreMetrics(scores, posts, preflights, spend) {
  const scoreIds = Object.keys(scores);
  return scoreIds.reduce((obj, score) => {
    const {
      scoreSum,
      highestCount,
      highestSpend,
      preflightHighestCount,
    } = scores[score];

    const average = posts ? scoreSum / posts : null;
    const rate = posts ? highestCount / posts : null;
    const preflightRate = preflights ? preflightHighestCount / preflights : null;
    const spendRate = spend ? highestSpend / spend : null;

    return {
      ...obj,
      [`averageScore::${score}`]: {
        value: average ?? 0,
        label: toPercent(average) ?? 'N/A',
      },
      [`scoreRate::${score}`]: {
        value: rate ?? 0,
        label: toPercent(rate) ?? 'N/A',
      },
      [`preflightScoreRate::${score}`]: {
        value: preflightRate ?? 0,
        label: toPercent(preflightRate) ?? 'N/A',
      },
      [`qualitySpendRate::${score}`]: {
        value: spendRate ?? 0,
        label: toPercent(spendRate) ?? 'N/A',
      },
    };
  }, scoreMetricDefaults(scoreIds));
}

function getBinMetrics(bin, scores) {
  const { coreAssetCount } = bin;
  const {
    activatedMasterAssets,
    adFormats,
    auditAssets,
    channels,
    impressions,
    inflightPosts,
    markets,
    placements,
    preflightMasterAssets,
    scorePosts,
    spend,
    ...scoreData
  } = getBinTotals(bin.records, scores);

  const activationRate = coreAssetCount > 0 ? activatedMasterAssets / coreAssetCount : 0;
  const averageSpend = auditAssets > 0 ? spend / auditAssets : 0;
  const repurposedRate = activatedMasterAssets > 0 ? auditAssets / activatedMasterAssets : 0;
  const reusageRate = activatedMasterAssets > 0 ? inflightPosts / activatedMasterAssets : 0;

  return {
    activationRate: {
      value: Math.round(activationRate * 1000) / 1000,
      label: toPercent(activationRate, 1),
    },
    adFormatsActivated: {
      value: adFormats,
      label: adFormats === 0 ? 'N/A' : adFormats,
    },
    assets: { value: coreAssetCount },
    assetsActivated: { value: activatedMasterAssets },
    assetsNotActivated: { value: coreAssetCount - activatedMasterAssets },
    averageSpend: {
      value: averageSpend,
      label: toSpend(averageSpend),
    },
    channelsActivated: { value: channels },
    impressions: {
      value: impressions,
      label: toCount(impressions),
    },
    marketsActivated: { value: markets },
    placementsActivated: {
      value: placements,
      label: placements === 0 ? 'N/A' : placements,
    },
    repurposedRate: {
      value: repurposedRate,
      label: `${repurposedRate.toFixed(1)}x`,
    },
    reusageRate: {
      value: reusageRate,
      label: `${reusageRate.toFixed(1)}x`,
    },
    spend: {
      concise: toConciseSpend(spend),
      value: spend,
      label: toSpend(spend),
    },
    totalAssets: {
      value: auditAssets,
      concise: toConciseCount(auditAssets),
    },
    totalPosts: {
      value: inflightPosts,
      concise: toConciseCount(inflightPosts),
    },
    ...updateScoreMetrics(scoreData, scorePosts, preflightMasterAssets, spend),
  };
}

export function getItems(bins, segments, scores) {
  return Object.values(bins).map((bin, index) => ({
    index: { value: index + 1 },
    id: { value: bin.tag },
    ...getBinSegments(bin, segments),
    ...getBinMetrics(bin, scores),
  }));
}
