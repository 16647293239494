import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Divider,
  Dropdown,
  removeObjectByValue,
} from '@makeably/creativex-design-system';
import { metricTooltips } from 'components/creative_lifecycle/shared';
import ItemsTable from 'components/molecules/ItemsTable';
import {
  addErrorToast,
} from 'components/organisms/Toasts';
import BreakdownDrawer from 'components/reporting/BreakdownDrawer';
import ConfigureReport from 'components/reporting/ConfigureReport';
import {
  getBins,
  getItems,
  preprocessAssets,
} from 'components/reporting/connectedDataUtilities';
import MetricVisualization from 'components/reporting/MetricVisualization';
import ReportingFilter from 'components/reporting/ReportingFilter';
import ReportTags from 'components/reporting/ReportTags';
import {
  propertiesProps,
  dateOptionProps,
  scoreProps,
  attributeLookupsProps,
} from 'components/reporting/shared';
import {
  calcPropertiesJson,
  getHeaders,
  getScoreMetric,
  parseProperties,
  updateVizMetric,
} from 'components/reporting/utilities';
import {
  arrayIf,
  sortObjectArray,
} from 'utilities/array';
import { saveItemsCsvFile } from 'utilities/file';
import { getObjFilterTest } from 'utilities/filtering';
import { getItemSortBy } from 'utilities/item';
import { track } from 'utilities/mixpanel';
import { removeProperty } from 'utilities/object';
import { get } from 'utilities/requests';
import {
  editReportingReportPath,
  connectedDataAssetsReportingReportsPath,
} from 'utilities/routes';
import styles from './ConnectedDataReport.module.css';

const propTypes = {
  attributeLookups: attributeLookupsProps.isRequired,
  canViewSpend: PropTypes.bool.isRequired,
  dateOptions: PropTypes.arrayOf(dateOptionProps).isRequired,
  initialDescription: PropTypes.string.isRequired,
  initialProperties: propertiesProps.isRequired,
  initialTitle: PropTypes.string.isRequired,
  scores: PropTypes.arrayOf(scoreProps).isRequired,
  type: PropTypes.string.isRequired,
  uuid: PropTypes.string,
};

const defaultProps = {
  uuid: undefined,
};

const segments = [
  {
    label: 'Core Assets',
    group: true,
    value: 'coreAssets',
  },
  {
    label: 'Core Asset Brand',
    value: 'cpeBrand',
  },
  {
    label: 'Core Asset Campaign Name',
    value: 'cpeCampaign',
  },
  {
    label: 'Core Asset File Name',
    value: 'cpeFilename',
  },
  {
    label: 'Core Asset ID',
    value: 'masterAssetId',
  },
  {
    label: 'Core Asset Measurement Partner',
    value: 'cpeMeasurementPartner',
  },
  {
    label: 'Core Asset Production Partner',
    value: 'cpeProductionPartner',
  },
  {
    label: 'In-Flight',
    group: true,
    value: 'inFlight',
  },
  {
    label: 'Ad Format',
    value: 'adFormat',
  },
  {
    label: 'Asset Type',
    value: 'creativeType',
  },
  {
    label: 'Brand',
    value: 'brand',
  },
  {
    label: 'Channel',
    value: 'channel',
  },
  {
    label: 'Core Asset Status',
    value: 'coreAssetStatus',
  },
  {
    label: 'Market',
    value: 'market',
  },
  {
    label: 'Placement',
    value: 'placement',
  },
];

const coreAssetMetrics = [
  {
    label: 'Activation\nRate',
    tooltip: metricTooltips.activationRate,
    value: 'activationRate',
  },
  {
    label: 'Core Assets\nActivated',
    tooltip: metricTooltips.assetsActivated,
    value: 'assetsActivated',
  },
  {
    label: 'Core Assets\nNot Activated',
    tooltip: metricTooltips.assetsNotActivated,
    value: 'assetsNotActivated',
  },
  {
    label: 'Core Assets\nUploaded',
    tooltip: metricTooltips.assets,
    value: 'assets',
  },
  {
    label: 'Repurposed\nRate',
    tooltip: metricTooltips.repurposedRate,
    value: 'repurposedRate',
  },
  {
    label: 'Reusage\nRate',
    tooltip: metricTooltips.reusageRate,
    value: 'reusageRate',
  },
];

const campaignReachMetrics = [
  {
    label: 'Ad\nFormats\nActivated',
    tooltip: metricTooltips.adFormats,
    value: 'adFormatsActivated',
  },
  {
    label: 'Channels\nActivated',
    tooltip: metricTooltips.channels,
    value: 'channelsActivated',
  },
  {
    label: 'Markets\nActivated',
    tooltip: metricTooltips.markets,
    value: 'marketsActivated',
  },
  {
    label: 'Placements\nActivated',
    tooltip: metricTooltips.placements,
    value: 'placementsActivated',
  },
  {
    label: 'Total\nAssets',
    tooltip: metricTooltips.totalAssets,
    value: 'totalAssets',
  },
  {
    label: 'Total\nPosts',
    tooltip: metricTooltips.totalPosts,
    value: 'totalPosts',
  },
];

const spendMetrics = [
  {
    label: 'Activated\nImpressions',
    tooltip: metricTooltips.adImpressions,
    value: 'impressions',
  },
  {
    label: 'Activated\nMedia\nSpend',
    tooltip: metricTooltips.adMediaSpend,
    value: 'spend',
  },
  {
    label: 'Average Spend\nPer Asset',
    tooltip: metricTooltips.averageSpendPerAsset,
    value: 'averageSpend',
  },
];

async function getAssets(dateOption, includePreflights) {
  const params = {
    date_type: dateOption.type,
    start_date: dateOption.startDate,
    preflights: includePreflights,
  };

  return get(connectedDataAssetsReportingReportsPath(params));
}

function getScoreMetrics(scores, canViewSpend) {
  const sortedScores = scores.toSorted((a, b) => b.isDefault - a.isDefault);
  return sortedScores.reduce((arr, score) => {
    const newEntries = [
      getScoreMetric(score, 'averageScore'),
      getScoreMetric(score, 'scoreRate'),
      {
        label: `Preflight ${score.metricLabels?.scoreRate}`,
        tooltip: metricTooltips.preflightQualityRate,
        type: 'percent',
        value: `preflightScoreRate::${score.versionId}`,
      },
      ...arrayIf(canViewSpend, getScoreMetric(score, 'qualitySpendRate')),
    ];
    const orderedEntries = sortObjectArray(newEntries, 'label');

    return [
      ...arr,
      ...orderedEntries,
    ];
  }, []);
}

function getMetrics(scores, canViewSpend) {
  const campaignFootprintMetrics = sortObjectArray([
    ...campaignReachMetrics,
    ...arrayIf(canViewSpend, ...spendMetrics),
  ], 'label');

  return [
    {
      label: 'Core Assets',
      group: true,
      value: 'coreAssets',
    },
    ...coreAssetMetrics,
    {
      label: 'Campaign Footprint',
      group: true,
      value: 'campaignFootprint',
    },
    ...campaignFootprintMetrics,
    {
      label: 'Scores',
      group: true,
      value: 'scores',
    },
    ...getScoreMetrics(scores, canViewSpend),
  ];
}

function ConnectedDataReport({
  attributeLookups,
  canViewSpend,
  dateOptions,
  initialDescription,
  initialProperties,
  initialTitle,
  scores,
  type,
  uuid,
}) {
  const [selectedDateOption, setSelectedDateOption] = useState();
  const [selectedFilters, setSelectedFilters] = useState({});
  const [selectedMetrics, setSelectedMetrics] = useState([]);
  const [selectedSegments, setSelectedSegments] = useState([]);
  const [sort, setSort] = useState();
  const [vizMetric, setVizMetric] = useState(updateVizMetric(undefined, selectedMetrics));
  const [userSorted, setUserSorted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [assets, setAssets] = useState([]);
  const [preflights, setPreflights] = useState([]);
  const [preflightsFetched, setPreflightsFetched] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [calculating, setCalculating] = useState(false);
  const [page, setPage] = useState(1);
  const segmentsWithoutGroups = sortObjectArray(segments.filter((segment) => !segment.group), 'label');

  const processedAssets = useMemo(
    () => preprocessAssets(assets, preflights, attributeLookups, scores),
    [assets, preflights, attributeLookups],
  );

  const filteredAssets = useMemo(() => {
    const filterTest = getObjFilterTest(selectedFilters);

    return processedAssets.filter(filterTest);
  }, [processedAssets, selectedFilters]);

  const items = useMemo(() => {
    setCalculating(true);
    const bins = getBins(selectedSegments, filteredAssets);
    const allItems = getItems(bins, selectedSegments, scores);
    setCalculating(false);
    return allItems;
  }, [filteredAssets, selectedSegments]);

  const sortedItems = useMemo(() => {
    if (sort) {
      const byKeyDir = getItemSortBy(sort.key, sort.asc);
      const sorted = items.slice().sort(byKeyDir);
      return sorted.map((item, index) => ({
        ...item,
        index: { value: index + 1 },
      }));
    }
    return items;
  }, [items, sort]);

  const headers = useMemo(
    () => getHeaders(selectedSegments, selectedMetrics, vizMetric, setVizMetric),
    [selectedSegments, selectedMetrics, vizMetric],
  );

  const filterCount = useMemo(
    () => Object.values(selectedFilters).flat().length,
    [selectedFilters],
  );

  useEffect(() => {
    const parsed = parseProperties(initialProperties, {
      dateOptions,
      metrics: getMetrics(scores, canViewSpend),
      segments,
    });

    setSelectedDateOption(parsed.selectedDateOption);
    setSelectedFilters(parsed.selectedFilters);
    setSelectedMetrics(parsed.selectedMetrics);
    setSelectedSegments(parsed.selectedSegments);
    setSort(parsed.sort);
    setVizMetric(parsed.vizMetric);
  }, [initialProperties]);

  useEffect(() => {
    async function fetchAssets() {
      setLoading(true);
      const response = await getAssets(selectedDateOption, !preflightsFetched);

      if (response.isError) {
        const isTimeout = response.status === 504;
        const message = isTimeout ? 'The data request has timed out' : 'The data could not be loaded';

        addErrorToast(message);
        setLoading(false);
        return;
      }
      setAssets(response.data.assets);
      if (!preflightsFetched) {
        setPreflightsFetched(true);
        setPreflights(response.data.preflights);
      }
      setLoading(false);
    }

    if (selectedDateOption) {
      fetchAssets();
    }
  }, [selectedDateOption]);

  useEffect(() => {
    if (vizMetric && !userSorted) {
      setSort({
        asc: false,
        key: vizMetric.value,
      });
    }
  }, [vizMetric, userSorted]);

  useEffect(() => {
    setPage(1);
  }, [filteredAssets, selectedSegments, selectedMetrics, vizMetric]);

  const propertiesJson = calcPropertiesJson({
    selectedDateOption,
    selectedFilters,
    selectedMetrics,
    selectedSegments,
    sort,
    vizMetric,
  });
  const hasChanged = propertiesJson !== JSON.stringify(initialProperties);

  const handleSave = (reportUuid) => {
    window.location.href = editReportingReportPath(reportUuid);
  };

  const handleFiltersChange = (filters) => {
    setSelectedFilters(filters);

    track('apply_filter', {
      filters,
      type,
    });
  };

  const handleDateChange = (date) => {
    setSelectedDateOption(date);

    track('apply_date_change', {
      date: date.label,
      type,
    });
  };

  const handleRemoveFilter = (key) => {
    setSelectedFilters((last) => removeProperty(last, key));
  };

  const handleUpdateMetrics = (selected) => {
    setVizMetric((metric) => updateVizMetric(metric, selected));
    setSelectedMetrics(selected);
  };

  const handleRemoveMetric = (option) => {
    handleUpdateMetrics(removeObjectByValue(selectedMetrics, option));
  };

  const handleRemoveSegment = (option) => {
    setSelectedSegments((current) => removeObjectByValue(current, option));
  };

  const handleSortChange = (value) => {
    setUserSorted(true);
    setSort(value);
  };

  const emptyStateMessage = () => {
    if (loading) {
      return '';
    }
    if (calculating) {
      return 'Calculating metrics';
    }
    if (assets.length === 0) {
      return 'No data to display';
    }
    if (sortedItems.length === 0 && filterCount !== 0) {
      return 'Remove filters to see data';
    }
    return null;
  };

  const emptyState = () => {
    if (!emptyStateMessage) {
      return null;
    }
    return (
      <div className="u-flexRow u-justifyCenter u-paddingTop-24">
        <p>{ emptyStateMessage }</p>
      </div>
    );
  };

  return (
    <>
      <ConfigureReport
        hasChanged={hasChanged}
        initialDescription={initialDescription}
        initialTitle={initialTitle}
        propertiesJson={propertiesJson}
        type={type}
        uuid={uuid}
        onExportCsv={(title) => saveItemsCsvFile(title, sortedItems, headers)}
        onSave={handleSave}
      />
      <Card padding={false}>
        <div className={styles.top}>
          <div className="u-flexRow u-justifyBetween">
            <div className="u-flexRow u-gap-16">
              <Button
                label="Setup"
                variant="secondary"
                onClick={() => setDrawerOpen(true)}
              />
              <ReportingFilter
                isOpen={filterOpen}
                records={processedAssets}
                segments={segmentsWithoutGroups}
                selections={selectedFilters}
                onClose={() => setFilterOpen(false)}
                onOpen={() => setFilterOpen(true)}
                onSelectionsChange={handleFiltersChange}
              />
            </div>
            <Dropdown
              disabled={loading}
              menuProps={{ size: 'medium' }}
              options={dateOptions}
              selected={selectedDateOption}
              size="medium"
              onChange={handleDateChange}
            />
          </div>
          <ReportTags
            filters={selectedFilters}
            removeFilter={handleRemoveFilter}
            removeSelectedMetric={handleRemoveMetric}
            removeSelectedSegment={handleRemoveSegment}
            segments={segmentsWithoutGroups}
            selectedMetrics={selectedMetrics}
            selectedSegments={selectedSegments}
            setSelectedSegments={setSelectedSegments}
            onFilterClick={() => setFilterOpen(true)}
            onMetricClick={() => setDrawerOpen(true)}
            onSegmentClick={() => setDrawerOpen(true)}
          />
        </div>
        <Divider />
        <MetricVisualization
          displayMetric={vizMetric}
          items={sortedItems}
          loading={loading || calculating}
          selectedMetrics={selectedMetrics}
          selectedSegments={selectedSegments}
        />
        <Divider />
        { emptyState }
        <div className={styles.table}>
          <ItemsTable
            headers={headers}
            items={sortedItems}
            page={page}
            sort={sort}
            onPageChange={(v) => setPage(v)}
            onSortChange={handleSortChange}
          />
        </div>
      </Card>
      <BreakdownDrawer
        isOpen={drawerOpen}
        metrics={getMetrics(scores, canViewSpend)}
        segments={segments}
        selectedMetrics={selectedMetrics}
        selectedSegments={selectedSegments}
        setSelectedMetrics={handleUpdateMetrics}
        setSelectedSegments={setSelectedSegments}
        onClose={() => setDrawerOpen(false)}
      />
    </>
  );
}

ConnectedDataReport.propTypes = propTypes;
ConnectedDataReport.defaultProps = defaultProps;

export default ConnectedDataReport;
